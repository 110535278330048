<script>
import feather from 'feather-icons';
import CryotherapieSingle from './CryotherapieSingle.vue';
import axios from 'axios';
import apiUrl from '../../data/prestations';

export default {
	components: {
		CryotherapieSingle,
	},
	data: () => {
		return {
			cryotherapies: null,
			cryotherapieHeading: 'La Cryotherapie',
		};
	},
	methods: {
		getCryotherapies() {
			axios.get(`${apiUrl}/api/cryotherapies?fields[0]=title&populate=vignette`, {
			headers: {
				Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
			}}).then(response => {
				this.cryotherapies = response.data.data
			});
		}
	},
	mounted() {
		feather.replace();
		this.getCryotherapies();
	},
};
</script>

<template>
	<!-- Cryotherapies grid -->
	<section class="pt-2">
		<!-- Cryotherapies grid title -->
		<div class="text-center">
			<p
				class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light"
			>
				{{ cryotherapieHeading }}
			</p>
		</div>
		<!-- Cryotherapies grid -->
		<div
			class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:gap-10"
		>
			<CryotherapieSingle
				v-for="cryotherapie in cryotherapies"
				:key="cryotherapie.id"
				:cryotherapie="cryotherapie"
			/>
		</div>
	</section>
</template>

<style scoped></style>
