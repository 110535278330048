<script>
import Button from '../reusable/Button.vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from 'moment';
import ProductType from './const.js';
import SlotTime from './SlotTime.vue';

export default {
    props: ["dateSelected", "dateBloquees", "dateDisabled", "prestationSelected", "productSelected", "additionnalValue"],
    data() {
      return {
        slottime: {
          attributes: {
            debut: null,
            fin: null,
            duree: null
          }
        },
        additionnal: null,
        datePicker: this.dateSelected,
        format: "dd-MM-yyyy"
      };
    },
    emits: ['update:dateSelected', 'update:additionnalValue'],
    watch: {
      datePicker: function(value) {
        this.slottime = this.dateBloquees.filter(data =>
          moment(new Date(data.attributes.date)).isSame(value, 'day')
        )[0];
        this.$emit('update:dateSelected', value);
      },
      dateSelected: function(value) {
        this.datePicker = value
      }
    },
    components: { Button, Datepicker, SlotTime },
    computed: {
      dateFormat() {
        if(this.datePicker) {
          return moment(this.datePicker).locale("fr").format('dddd, D MMMM YYYY');
        }
        return null;
      },
      autoRange() {
        return this.productSelected === ProductType.SEMAINE ? 4 : null;
      },
      rangeSelect() {
        return (this.productSelected === ProductType.SEMAINE);
      },
      allowedDate() {
        return this.productSelected === ProductType.SEANCE ? this.dateBloquees.map(data => new Date(data.attributes.date)) : [];
      },
      disabledDates() {
        return this.productSelected === ProductType.SEANCE ? [] : this.dateDisabled;
      },
      isJournee() {
        return this.productSelected === ProductType.JOURNEE
      },
      isDemiJournee() {
        return this.productSelected === ProductType.DEMIJOURNEE
      },
      isSeance() {
        return this.productSelected === ProductType.SEANCE && this.datePicker != null
      }
    },
    methods: {
      changeAdditonnalValue(additionnalValue) {
        this.additionnal = additionnalValue;
        this.$emit('update:additionnalValue', additionnalValue);
      }
    }
}
</script>

<template>
  <Datepicker
    v-model="datePicker"
    :range="rangeSelect"
    autoApply
    locale="fr"
    :allowed-dates="allowedDate"
    :enableTimePicker="false"
    :weekPicker="false"
    :close-on-auto-apply="!rangeSelect"
    :min-date="new Date()"
    :format="format"
    :auto-range="autoRange"
    :highlight="disabledDates"
    :disabled-dates="disabledDates"
    highlight-disabled-days
    no-disabled-range
    prevent-min-max-navigation
    placeholder="Sélectionnez une date"
    ></Datepicker>
  <div v-if="isJournee" class="overflow">
    <input type="hidden" date="journee"/>
  </div>
  <div v-if="isSeance" class="overflow">
    <SlotTime :slottime="slottime" :dateSelected="this.datePicker" @onChange="changeAdditonnalValue" :selected="additionnal" />
  </div>
  <div v-if="isDemiJournee" class="overflow">
    <div class="mt-5 mb-5 text-indigo-600 dark:text-primary-light text-xl" v-if="dateFormat">Pour le {{ dateFormat }}</div>
    <div class="flex justify-center gap-5">
      <div class="justify-center">
        <Button
          type="button"
          title="Matin"
          class="p-4
                sm:px-6
                py-2
                sm:py-2.5
                text-white
                bg-indigo-400
                rounded-md"
          v-if="dateFormat"
          :class="[additionnal == 'am' ? 'bg-indigo-600' : 'hover:ring-2 hover:ring-indigo-900 duration-500']"
          @click="changeAdditonnalValue('am')"
        />
      </div>
      <div class="justify-center">
        <Button
          type="button"
          title="Après-midi"
          class="p-4
                sm:p-6
                py-2
                sm:py-2.5
                text-white
                bg-indigo-400
                rounded-md"
          v-if="dateFormat"
          :class="[additionnal == 'ap' ? 'bg-indigo-600' : 'hover:ring-2 hover:ring-indigo-900 duration-500']"
          @click="changeAdditonnalValue('ap')"
        />
      </div>
    </div>
  </div>
</template>
<style scoped>
@media all and (max-height:500px) {
.overflow {
    overflow: auto;
    max-height: 20vh;
}
}
</style>
