const defaultPrestations = [
    {
        "id": 0,
        "name": "Réservation d'un créneau",
        "detail": "Réserver une séance lors d'un créneau horaire",
        "infoDetail": "Créneau de %s minutes",
        "disable": "Aucune réservation par créneau horaire n'est disponible actuellement",
        "selected": false,
        "type": "seance"
    },
    {
        "id": 1,
        "name": "Réservations",
        "detail": "Réserver une de nos préstations",
        "infoDetail": "Pour 1/2 journée, une jounée, ..." ,
        "disable": "Aucune réservation n'est disponible",
        "selected": false,
        "type": "default"
    }
];

export default defaultPrestations;
