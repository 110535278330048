<template>
  <div class="centerDiv">
    <ul class="progressbar">
      <li :class="[i <= progress ? 'active' : '']" v-for="(val, i) in steps" :key="i"><span class="hidden lg:block">{{val.name}}</span></li>
    </ul>
  </div>
</template>
<script>
export default {
  props:['steps', 'progress'],
  data () {
    return {
    }
  },
}
</script>

<style scoped>
.centerDiv {
  display: flex;
  justify-content: center;
}
.progressbar{
  counter-reset: step;
  position: relative;
  z-index: 3;
}
.progressbar li{
  float: left;
  position: relative;
  text-align: center;
  margin-left: 20px;
}
.progressbar li:before{
  content:counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  border: 2px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}
.progressbar li:after{
  content: '';
  position: absolute;
  width:100%;
  height: 3px;
  background: #979797;
  top: 15px;
  left: -67%;
  z-index: -1;
}
.progressbar li.active:before{
  border-color: #3aac5d;
  background: #3aac5d;
  color: white
}
.progressbar li.active:after{
  background: #3aac5d;
}
.progressbar li.active - li:after{
  background: #3aac5d;
}
.progressbar li.active - li:before{
  border-color: #3aac5d;
  background: #3aac5d;
  color: white
}
.progressbar li:first-child:after{
  content: none;
}
</style>
