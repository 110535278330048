<script>
import AppBanner from '@/components/shared/AppBanner';
import ProjectsGrid from '../components/projects/ProjectsGrid.vue';
import PartenairesGrid from '../components/partenaires/PartenairesGrid.vue';
import axios from 'axios';
import apiUrl from '../data/prestations';

export default {
	name: 'Home',
	data: () => {
		return {
			accueil: null,
			imagePath: (process.env.VUE_APP_CLOUDINARY || "")!=""? "" : apiUrl
		};
	},
	components: {
		AppBanner,
		ProjectsGrid,
		PartenairesGrid,
	},
	methods: {
		getAccueil() {
			axios.get(`${apiUrl}/api/accueil?populate=download`, {
			headers: {
				Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
			}}).then(response => {
				this.accueil = response.data.data?.attributes
			}).catch(function () {
			});
		},
	},
	mounted() {
		this.getAccueil();
	}
};
</script>

<template>
	<div class="container mx-auto">
		<!-- Banner -->
		<AppBanner class="mb-5 sm:mb-8" :accueil="accueil" />

		<!-- Projects -->
		<ProjectsGrid />
		<!-- Partenairess -->
		<PartenairesGrid />

	</div>
</template>

<style scoped></style>
