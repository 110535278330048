const DEMIJOURNEE = "demi-journee";
const SEMAINE = "semaine";
const JOURNEE = "journee";
const SEANCE = "seance";
const NBRESERVATIONSEANCE = 2;

export default {
    DEMIJOURNEE: DEMIJOURNEE,
    SEMAINE: SEMAINE,
    JOURNEE: JOURNEE,
    SEANCE: SEANCE,
    NBRESERVATIONSEANCE: NBRESERVATIONSEANCE
}
