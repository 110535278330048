<script>

import defaultPrestations from './prestations.js';
import ProductType from './const.js';
import moment from 'moment';
import axios from 'axios';
import feather from 'feather-icons';
import apiUrl from '../../data/prestations';
import defaultSteps from './steps.js';
import ReservationMultiStep from './ReservationMultiStep.vue';
import ReservationDate from './ReservationDate.vue';
import ReservationPrestation from './ReservationPrestation.vue';
import ReservationProduit from './ReservationProduit.vue';
import ReservationCoordonnees from './ReservationCoordonnees.vue';

export const emptyForm = {
	prestation: null,
	productType: null,
	date: null,
	additionnalValue: null,
	details: {
		societe: null,
		firstname: null,
		lastname: null,
		email: null,
		address: null,
		phone: null,
		message: null
	}
}

export default {
	props: ['showModal', 'modal', 'categories'],
	components: {
		ReservationMultiStep,
		ReservationDate,
		ReservationPrestation,
		ReservationProduit,
		ReservationCoordonnees
	},
	data() {
		var steps = defaultSteps;
		return {
			today: moment().format('YYYY-MM-DD'),
			formData: emptyForm,
			steps,
			productList: [],
			dateBloquees: [],
			dateBloqueesDate: [],
			dureeList: [],
			dateDisabled: []
		};
	},
	mounted() {
		feather.replace();
		this.getProduits();
		this.getDateBloquees();
		this.getDateDisabled();
	},
	updated() {
		feather.replace();
	},
	methods: {
		getDateBloquees() {
			axios.get(`${apiUrl}/api/date-bloquees?filters[raison][$eq]=seance&filters[date][$gte]=${this.today}`, {
			headers: {
				Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
			}}).then(response => {
				this.dateBloquees = response.data.data;
				this.dureeList = response.data.data.map(data => data.attributes.duree);
				this.dateBloqueesDate = response.data.data.map(data => new Date(data.attributes.date));
			});
		},
		getDateDisabled() {
			axios.get(`${apiUrl}/api/date-bloquees?filters[date][$gte]=${this.today}`, {
			headers: {
				Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
			}}).then(response => {
				this.dateDisabled = response.data.data.map(data => new Date(data.attributes.date));
			});
		},
		getProduits() {
			axios.get(`${apiUrl}/api/produits`, {
			headers: {
				Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
			}}).then(response => {
				this.productList = response.data.data;
				this.productList.filter(product => {
					product.selected = false;
					product.detail = false;
				});
			});
		},
		createReservation() {
			axios.post(`${apiUrl}/api/reservations`, {
				data: {
					Additonnal: this.formData.additionnalValue,
					Address: this.formData.address,
					Date: moment(this.formData.date).format('YYYY-MM-DD'),
					Email: this.formData.details.email,
					Message: this.formData.details.message,
					Name: `${this.formData.details.firstname} ${this.formData.details.lastname}`,
					Phone: this.formData.details.phone,
					Prestation: defaultPrestations[this.formData.prestation].type,
					Product: this.formData.productType,
					Societe: this.formData.details.societe,
					MailConfirmation: this.formData.productType === ProductType.SEANCE ? true : false,
					publishedAt: this.formData.productType === ProductType.SEANCE ? new Date() : null
				}
			}, {
				headers: {
					Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
					Accept: "application/json",
					"Content-Type": "application/json"
				}
			}).then(() => {
				this.$toast.success('Votre demande de réservation a bien été prise en compte.', {
					position: 'top',
					duration: 5000
				});
			});
		},
		actionModal() {
			this.steps = defaultSteps;
			this.formData = emptyForm;
			this.$refs.reservation.resetReservation();
			this.$refs.reservationprestation.resetReservation();
			this.$refs.reservationproduit.updateProductList();
			this.showModal();
		},
		onPrevious(stepIndex) {
			this.steps[stepIndex].step_valid=false;
			if (stepIndex < 3) {
				this.formData.date = null;
			}
		},
		validateStep(stepIndex){
			switch (stepIndex) {
				case 0:
					if (this.formData.prestation != null) {
						this.steps[stepIndex].step_valid=true;
						this.$refs.reservation.submitStep();
					}
					break;
				case 1:
					if (this.formData.productType != null) {
						this.steps[stepIndex].step_valid=true;
						this.$refs.reservation.submitStep();
					}
					break;
				case 2:
					if (this.formData.date != null) {
						if (this.formData.productType === ProductType.DEMIJOURNEE &&
							!["am", "ap"].includes(this.formData.additionnalValue)) {
							return;
						}
						if (this.formData.productType === ProductType.SEANCE &&
							(this.formData.additionnalValue == null || !this.formData.additionnalValue.length)) {

							return;
						}
						this.steps[stepIndex].step_valid=true;
						this.$refs.reservation.submitStep();
					}
					break;
				case 3:
					if (this.formData.details.firstname != null &&
						this.formData.details.lastname != null &&
						this.formData.details.email != null
						) {
						this.steps[stepIndex].step_valid=true;
						this.$refs.reservation.submitStep();
					}
					break;
			}
		},
		submitForm(){
			this.createReservation();
			this.steps = defaultSteps;
			this.formData = emptyForm;
			this.showModal();
			this.$refs.reservation.resetReservation();
			this.$refs.reservationprestation.resetReservation();
			this.$refs.reservationproduit.updateProductList();
		},
	},
};
</script>

<template>
	<transition name="fade">
		<div v-show="modal" class="font-general-regular fixed inset-0 z-30">
			<!-- Modal body background as backdrop -->
			<div
				v-show="modal"
				@click="actionModal()"
				class="bg-filter bg-black bg-opacity-50 fixed inset-0 w-full h-full z-20"
			></div>
			<!-- Modal content -->
			<main
				class="flex flex-col items-center justify-center h-full w-full h-auto"
			>
				<transition name="fade-up-down">
					<div
						v-show="modal"
						class="modal-wrapper flex items-center z-30 h-auto"
					>
						<div
							class="modal max-w-md mx-5 xl:max-w-xl lg:max-w-xl md:max-w-xl bg-secondary-light dark:bg-primary-dark max-h-screen shadow-lg flex-row rounded-lg relative h-auto max-h-screen"
						>
							<div
								class="modal-header flex justify-between gap-10 p-5 border-b border-ternary-light dark:border-ternary-dark"
							>
								<div class="text-left">
								<h5
									class=" text-indigo-600 dark:text-primary-light text-xl"
								>
									Effectuer votre reservation<br>
								</h5>
								<span class="italic text-sm hidden md:block">
									Votre réservation ne sera effective qu’à l’encaissement de votre acompte qui sera obligatoirement confirmée par l’envoi d’un email
								</span>
								</div>

								<button
									class="px-4 text-primary-dark dark:text-primary-light"
									@click="actionModal()"
								>
									<i data-feather="x"></i>
								</button>
							</div>

							<div class="modal-body p-2 w-full h-auto grid">
								<ReservationMultiStep
									:steps="steps"
									ref="reservation"
									@onComplete="submitForm"
									@validateStep="validateStep"
									@onCancel="actionModal"
									@onPrevious="onPrevious"
									>
									<template #step1>
										<ReservationPrestation
											ref="reservationprestation"
											:dateBloquees="dateBloqueesDate"
											:durees="dureeList"
											v-model:prestationSelected="formData.prestation" />
									</template>

									<template #step2>
										<ReservationProduit
											ref="reservationproduit"
											:listProducts="productList"
											:prestationSelected="formData.prestation"
											v-model:productSelected="formData.productType" />
									</template>

									<template #step3>
										<ReservationDate
											:dateBloquees="dateBloquees"
											:dateDisabled="dateDisabled"
											:prestationSelected="formData.prestation"
											:productSelected="formData.productType"
											v-model:dateSelected="formData.date"
											v-model:additionnalValue="formData.additionnalValue"/>
									</template>

									<template #step4>
										<ReservationCoordonnees
											v-model:societe="formData.details.societe"
											v-model:firstname="formData.details.firstname"
											v-model:lastname="formData.details.lastname"
											v-model:email="formData.details.email"
											v-model:address="formData.details.address"
											v-model:phone="formData.details.phone"
											v-model:message="formData.details.message" />
									</template>
								</ReservationMultiStep>
							</div>
						</div>
					</div>
				</transition>
			</main>
		</div>
	</transition>
</template>

<style scoped>
.bg-gray-800-opacity {
	background-color: #2d374850;
}
.fade-up-down-enter-active {
	transition: all 0.3s ease;
}
.fade-up-down-leave-active {
	transition: all 0.3s ease;
}
.fade-up-down-enter {
	transform: translateY(10%);
	opacity: 0;
}
.fade-up-down-leave-to {
	transform: translateY(10%);
	opacity: 0;
}

.fade-enter-active {
	-webkit-transition: opacity 2s;
	transition: opacity 0.3s;
}
.fade-leave-active {
	transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
