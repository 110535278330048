<script>
import feather from 'feather-icons';
import PartenaireSingle from '../partenaires/PartenaireSingle.vue';
import axios from 'axios';
import apiUrl from '../../data/prestations';

export default {
	components: {
		PartenaireSingle,
	},
	data: () => {
		return {
			partenaires: null,
			partenairesHeading: 'Partenaires',
		};
	},
	methods: {
		getPartenaires() {
			axios.get(`${apiUrl}/api/partenaires?fields[0]=url&populate=logo`, {
			headers: {
				Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
			}}).then(response => {
				this.partenaires = response.data.data
			});
		}
	},
	mounted() {
		feather.replace();
		this.getPartenaires();
	},
};
</script>

<template>
	<!-- Projects grid -->
	<section class="pt-10 mt-10">
		<!-- Partenaires grid title -->
		<div class="text-center" v-if="partenaires">
			<p
				class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light"
			>
				{{ partenairesHeading }}
			</p>
		</div>
		<!-- Partenaires grid -->
		<div
			class="flex flex-row shrink place-content-center mt-12 mb-4"
		>
			<PartenaireSingle
				v-for="partenaire in partenaires"
				:key="partenaire.id"
				:partenaire="partenaire"
			/>
		</div>
	</section>
</template>

<style scoped></style>
