<script>
import CryotherapieGrid from '../components/cryotherapie/CryotherapieGrid.vue';

export default {
	name: 'Cryotherapie',
	components: {
		CryotherapieGrid,
	},
};
</script>

<template>
	<div class="container mx-auto">
		<CryotherapieGrid />
	</div>
</template>

<style scoped></style>
