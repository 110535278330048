<script>
import Button from '../reusable/Button.vue';
import Stepper from '../reusable/Stepper.vue';

export default {
  props:["steps"],
  components: { Stepper, Button },
  emits: ["onComplete", "validateStep", "onCancel", "onPrevious"],
  data(){
    return {
      activeStepIndex: 0,
    };
  },
  computed: {
    submitValue() {
      if (this.activeStepIndex === this.steps.length-1){
        return "Valider";
      }
      return "Suivant";
    }
  },
  methods: {
    submitStep(){
      if(!this.steps[this.activeStepIndex].step_valid){
        this.$emit("validateStep", this.activeStepIndex);
        return false;
      }
      this.activeStepIndex++;
      if(this.activeStepIndex == this.steps.length){
        //final step
        this.$emit("onComplete");
        return false;
      }
    },
    resetReservation(){
      this.$refs.reservation.reset();
      this.activeStepIndex = 0;
    },
    cancel(){
      this.resetReservation();
      this.$emit("onCancel");
    },
    previous(){
      if (this.activeStepIndex > 0) {
        this.activeStepIndex = this.activeStepIndex - 1;
        this.$emit("onPrevious", this.activeStepIndex);
      }
    }
  },
};
</script>
<template>
  <Stepper :steps="steps" :progress="activeStepIndex" />
  <div>
    <form ref="reservation" @submit.prevent="submitStep">
      <slot name="header">
        <div class="mt-2 mb-2 text-indigo-600 dark:text-primary-light text-xl">
          {{ steps[activeStepIndex].header }}
        </div>
      </slot>
      <div
        v-for="(step, index) in steps"
        :key="index"
        :id="'step'+(index+1)"
        v-show="activeStepIndex===index"
        class="vue-form-step oveflow">
        <slot :name="'step'+(index+1)" />
      </div>
      <slot name="footer">
        <div class="grid grid-cols-3 gap-5">
          <div class="mt-2 sm:pb-1">
            <Button
              :title="submitValue"
              class="px-4
              sm:px-6
              py-2
              sm:py-2.5
              text-white
              bg-green-600
              hover:bg-green-700
              rounded-md
              focus:ring-1 focus:ring-green-900 duration-500"
              type="submit"
              aria-label="Submit Request"
            />
          </div>
          <div class="mt-2 sm:pb-1">
            <Button
              type="button"
              title="Précédent"
              class=" px-3
              sm:px-5
              py-2 bg-gray-600 text-primary-light hover:bg-ternary-dark dark:bg-gray-200 dark:text-secondary-dark dark:hover:bg-primary-light
              rounded-md
              focus:ring-1 focus:ring-indigo-900 duration-500"
              @click="previous()"
              aria-label="Previous"
            />
          </div>
          <div class="mt-2 sm:pb-1">
            <Button
              type="button"
              title="Annuler"
              class=" px-4
              sm:px-6
              py-2 bg-gray-600 text-primary-light hover:bg-ternary-dark dark:bg-gray-200 dark:text-secondary-dark dark:hover:bg-primary-light
              rounded-md
              focus:ring-1 focus:ring-indigo-900 duration-500"
              @click="cancel()"
              aria-label="Close Modal"
            />
          </div>
        </div>
      </slot>
    </form>
  </div>
</template>

