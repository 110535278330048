<script>
import apiUrl from '../../data/prestations';

export default {
	props: ['cryotherapie'],
	data () {
		return {
			cryotherapieId: this.cryotherapie.id,
			cryotherapieValue: this.cryotherapie.attributes,
			api: apiUrl,
			imagePath: (process.env.VUE_APP_CLOUDINARY || "")!=""? "" : apiUrl
		}
	}
};
</script>

<template>
	<router-link
		:to="{ name: 'Cryotherapie', params: { id: cryotherapieId } }"
		class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
		aria-label="Single Cryotherapie"
	>
		<div>
			<img
				:src="imagePath+cryotherapieValue.vignette.data.attributes.url"
				:alt="cryotherapieValue.title"
				class="rounded-t-xl border-none"
			/>
		</div>
		<div class="text-center px-4 py-6">
			<p
				class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
			>
				{{ cryotherapieValue.title }}
			</p>
		</div>
	</router-link>
</template>

<style lang="scss" scoped></style>
