<script>
import axios from 'axios';
import apiUrl from '../../data/prestations';
import feather from 'feather-icons';
export default {
	data: () => {
		return {
			api: apiUrl,
			copyrightDate: new Date().getFullYear(),
			icones: null,
			projectName: 'Cryoloc',
		};
	},
	methods: {
		getIcones() {
			axios.get(`${apiUrl}/api/icones`, {
			headers: {
				Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
			}}).then(response => {
				this.icones = response.data.data
			});
		}
	},
	mounted() {
		this.getIcones();
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<div class="flex justify-center items-center text-center mt-12 mb-4">
		<div
			class="font-general-regular text-lg text-ternary-dark dark:text-ternary-light"
		>
			<ul class="flex gap-4 sm:gap-8">
				<a
					v-for="icone in icones"
					:key="icone.id"
					:href="icone.attributes.url"
					target="__blank"
					class="text-gray-400 hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm p-4 duration-500"
				>
					<i
						:data-feather="icone.attributes.icone"
						class="w-5 sm:w-8 h-5 sm:h-8"
					></i>
				</a>
			</ul>
		</div>
	</div>
	<div class="font-general-regular text-lg text-ternary-dark dark:text-ternary-light"
		>
		&copy; {{ copyrightDate }} {{ projectName }}
	</div>
</template>
<style scoped></style>
