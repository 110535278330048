<script>
export default {
    props: ["societe", "firstname", "lastname", "email", "address", "phone", "message"],
    emits: ["update:societe", "update:firstname", "update:lastname", "update:email", "update:address", "update:phone", "update:message"]
}
</script>

<template>
    <div class="ml-2 mr-2 overflow">
        <div class="mt-2">
            <input
                class="w-full px-5 py-2 border-1 border-gray-200 dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
                id="societe"
                name="societe"
                type="text"
                placeholder="Sociéte"
                aria-label="Societe"
                :value="societe"
                @input="$emit('update:societe', $event.target.value)"
            />
        </div>
        <div class="grid grid-cols-2 gap-5 mt-4">
            <div>
                <div class="mt-0">
                    <input
                        class="w-full px-5 py-2 border-1 border-gray-200 dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
                        id="firstname"
                        name="firstname"
                        type="text"
                        placeholder="Prénom *"
                        aria-label="Firstname"
                        :value="firstname"
                        @input="$emit('update:firstname', $event.target.value)"
                    />
                </div>
            </div>
            <div>
                <div class="mt-0">
                    <input
                        class="w-full px-5 py-2 border-1 border-gray-200 dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
                        id="lastname"
                        name="lastname"
                        type="text"
                        placeholder="Nom *"
                        aria-label="Lastname"
                        :value="lastname"
                        @input="$emit('update:lastname', $event.target.value)"
                    />
                </div>
            </div>
        </div>
        <div class="mt-4">
            <input
                class="w-full px-5 py-2 border-1 border-gray-200 dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
                id="email"
                name="email"
                type="text"
                placeholder="Email"
                aria-label="Email"
                :value="email"
                @input="$emit('update:email', $event.target.value)"
            />
        </div>
        <div class="mt-4">
            <input
                class="w-full px-5 py-2 border-1 border-gray-200 dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
                id="address"
                name="address"
                type="text"
                placeholder="Adresse"
                aria-label="Address"
                :value="address"
                @input="$emit('update:address', $event.target.value)"
            />
        </div>
        <div class="mt-4">
            <input
                class="w-full px-5 py-2 border-1 border-gray-200 dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
                id="phone"
                name="phone"
                type="text"
                placeholder="Téléphone"
                aria-label="Phone"
                :value="phone"
                @input="$emit('update:phone', $event.target.value)"
            />
        </div>
        <div class="mt-4">
            <textarea
                class="w-full px-5 py-2 border-1 border-gray-200 dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
                id="message"
                name="message"
                cols="14"
                rows="6"
                aria-label="Details"
                placeholder="Commentaires"
                :value="message"
                @input="$emit('update:message', $event.target.value)"
            ></textarea>
        </div>
    </div>
</template>
<style scoped>
@media all and (max-height:750px) {
.overflow {
    overflow: auto;
    max-height: 40vh;
}
}
</style>
