<script>
import ButtonSlot from '../reusable/ButtonSlot.vue';
import apiUrl from '../../data/prestations';
import ProductType from './const.js';
import axios from 'axios';
import moment from 'moment';

export default {
    props: ["slottime", "dateSelected", "selected"],
    components: { ButtonSlot },
    watch: {
        slottime() {
            this.selectedSlot = this.selected || '';
            this.debut = new Date("1970-01-01 " + this.slottime.attributes.debut);
            this.fin = new Date("1970-01-01 " + this.slottime.attributes.fin);
            this.duree = this.slottime.attributes.duree || 20;
        }
    },
    data() {
        return {
            selectedSlot: this.selected || '',
            debut: new Date("1970-01-01 " + this.slottime.attributes.debut),
            fin: new Date("1970-01-01 " + this.slottime.attributes.fin),
            duree: this.slottime.attributes.duree || 20,
            existingResa: [],
            dateQuery: moment(this.dateSelected).format('YYYY-MM-DD'),
            nbReservationSeance: ProductType.NBRESERVATIONSEANCE
        };
    },
    emits: ['onChange'],
    computed: {
        timeSlot(){
            let intervals = [];
            let i = 0;
            let value = null;
            for (let currTime = this.debut; currTime <= this.fin; currTime.setMinutes(currTime.getMinutes() + this.duree)) {
                i++;
                value = currTime.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' });
                intervals.push({ 'id': i, 'value': value });
            }
            return intervals;
        }
    },
    methods: {
        changeValue(value) {
            this.selectedSlot = value;
            this.$emit('onChange', value);
        },
        getReservations() {
            axios.get(`${apiUrl}/api/reservations?filters[prestation][$eq]=seance&filters[product][$eq]=seance&filters[date][$eq]=${this.dateQuery}&fields[0]=Additonnal`, {
            headers: {
                Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
            }}).then(response => {
                console.log(response.data.data)
                this.existingResa = response.data.data;
            });
        },
        getSeance() {
            axios.get(`${apiUrl}/api/date-bloquees?filters[raison][$eq]=seance&filters[date][$eq]=${this.dateQuery}`, {
            headers: {
                Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
            }}).then(response => {
                this.nbReservationSeance = response.data.data[0]?.attributes.nbReservationSeance || this.nbReservationSeance;
            });
        },
    },
    mounted() {
        this.getReservations();
        this.getSeance();
    }
}

</script>

<template>
    <div class="grid grid-cols-6 mt-2">
        <div
            v-for="interval in timeSlot"
            :key="interval.id"
            class="m-1"
        >
            <ButtonSlot
                :title="interval.value"
                :selected="this.selectedSlot"
                :available="this.nbReservationSeance - this.existingResa.filter(resa => resa.attributes.Additonnal === interval.value).length"
                @onUpdate="changeValue"
            />
        </div>
    </div>
</template>
