<script>
import feather from 'feather-icons';
export default {
    props: ["title", "available", "selected"],
    emits: ["onUpdate"],
    data () {
        return {};
    },
    computed: {
        buttonClass() {
            let classButton = "";
            if (this.available <= 0) {
                classButton = new Array("bg-red-600", "disable", "disabled");
            } else if (this.title == this.selected) {
                classButton =new Array("bg-indigo-600");
            } else {
                classButton = new Array("hover:ring-4", "hover:ring-indigo-900", "duration-500");
            }
            return classButton;
        },
    },
    mounted() {
        feather.replace();
    }
};
</script>

<style scoped>
.disabled {
  pointer-events: none;
  text-decoration: line-through;
}
.feather-14{
    width: 14px;
    height: 14px;
    display: inline;
}
</style>

<template>
    <button
        type="button"
        class="p-2 pl-2 pr-2 min-w-full text-white bg-indigo-400 rounded-md h-full"
        :class="buttonClass"
        @click="$emit('onUpdate', this.title)"
    >{{ title }}
    <div>
        <span v-for="n in available" :key="n">
            <i
                class="feather-14"
                data-feather="user"
                fill="white"
                :label="n"
            ></i>
        </span>
    </div>
    </button>
</template>
