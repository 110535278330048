<script>
import apiUrl from '../../data/prestations';

export default {
	props: ['partenaire'],
	data () {
		return {
			partenaireId: this.partenaire.id,
			partenaireValue: this.partenaire.attributes,
			api: apiUrl,
			imagePath: (process.env.VUE_APP_CLOUDINARY || "")!=""? "" : apiUrl
		}
	},
	computed: {
		alt() {
			if (this.partenaireValue)  {
				var url = this.partenaireValue.logo.data.attributes.url
				return url.substring(url.lastIndexOf('/') + 1);
			}
			return "";
		}
	}
};
</script>

<template>
	<div class="ml-10 mr-10">
		<a :href="partenaireValue.url" target="_blank">
			<img
				:src="imagePath+partenaireValue.logo.data.attributes.url"
				style="width:150px"
				:alt="alt"
			/>
		</a>
	</div>
</template>

<style lang="scss" scoped></style>
