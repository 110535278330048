<script>
import ProductType from './const.js';

export default {
    props: ["listProducts", "prestationSelected", "productSelected"],
    computed: {
        products: {
            get() {
                return this.updateProductList();
            }
        },
        isReadOnly() {
            return this.products.length == 1;
        }
    },
    emits: ['update:productSelected'],
    methods: {
        updateProductList() {
            let productsList;
            if (this.prestationSelected) {
                productsList = JSON.parse(JSON.stringify(this.listProducts.filter(product => product.attributes.type != ProductType.SEANCE)));
            } else {
                productsList = JSON.parse(JSON.stringify(this.listProducts.filter(product => product.attributes.type === ProductType.SEANCE)));
            }
            if (productsList.length == 1) {
                productsList[0].selected = true
                this.$emit('update:productSelected',  productsList[0].attributes.type);
            }
            return productsList;
        },
        selectOneProductFromDiv(index) {
            if (this.products.length > 1) {
                for (let i = 0; i < this.products.length; i++) {
                    if (index != i) {
                        this.products[i].selected = false
                    }
                }
                this.products[index].selected = !this.products[index].selected
                this.$emit('update:productSelected', this.products[index].selected ? this.products[index].attributes.type : null)
            }
        },
        selectOneProduct(event) {
            const index = event.target.value
            if (this.products.length > 1) {
                for (let i = 0; i < this.products.length; i++) {
                    if (index != i) {
                        this.products[i].selected = false
                    }
                }
                this.$emit('update:productSelected',this.products[index].selected ? this.products[index].attributes.type : null)
            }
        },
        toggleDetails(product, event) {
            product.detail = !product.detail;
            event.stopPropagation();
        }
    }
}
</script>
<template>
    <div class="grid overflow">
        <div v-for="(product, index) in products" :key="index"
            class="max-h-max flex text-left p-2 lg:m-2 ring-1 ring-grey hover:ring-2 hover:ring-indigo-600 duration-500"
            @click="selectOneProductFromDiv(index)"
        >
            <div class="ml-10 basis-3/4">
                {{ product.attributes.Name }}<br/>
                {{ product.attributes.Price != null ? product.attributes.Price + " €" : "" }}<button class="text-indigo-600" type="button" @click="toggleDetails(product, $event)" v-bind:class="[product?.attributes?.Description?.length ? '' : 'hidden']"> - Plus d'infos</button>
                <div class="m-2 italic" v-bind:class="[product.detail ? '' : 'hidden']">
                    {{ product.attributes.Description }}
                </div>
            </div>
            <div>
                <input :id="'productitem'+index" type="checkbox" :value="index" v-model="product.selected" :disabled="isReadOnly" @click="selectOneProduct" >
            </div>
        </div>
    </div>
</template>
<style scoped>
@media all and (max-height:500px) {
.overflow {
    overflow: auto;
    max-height: 30vh;
}
}
</style>
