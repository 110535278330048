<script>
import ProductType from './const.js';
import defaultPrestations from './prestations.js';

export default {
    props: ["dateBloquees", "durees", "prestationSelected"],
    data() {
        return {
            prestations: JSON.parse(JSON.stringify(defaultPrestations)),
        }
    },
    emits: ['update:prestationSelected'],
    methods: {
        resetReservation() {
            this.prestations = defaultPrestations;
        },
        isDisabled(prestation) {
            return (prestation.type == ProductType.SEANCE && !this.dateBloquees.length);
        },
        getDetail(prestation) {
            if (prestation.type == ProductType.SEANCE) {
                if(!this.dateBloquees.length) {
                    return prestation.disable;
                } else {
                    return prestation.infoDetail.replace('%s', this.durees.join(', '));
                }
            }
            return prestation.infoDetail;
        },
        selectOnePrestationFromDiv(index) {
            if (!this.isDisabled(this.prestations[index])) {
                for (let i = 0; i < this.prestations.length; i++) {
                    if (index != i) {
                        this.prestations[i].selected = false
                    }
                }
                this.prestations[index].selected = !this.prestations[index].selected
                this.$emit('update:prestationSelected', this.prestations[index].selected ? index : null)
            }
        },
        selectOnePrestation(event) {
            for (let index = 0; index < this.prestations.length; index++) {
                if (event.target.value != index) {
                    this.prestations[index].selected = false
                } else {
                    this.$emit('update:prestationSelected', this.prestations[index].selected ? index : null)
                }
            }
        },
    }
}
</script>
<template>
    <div class="grid overflow">
        <div v-for="(prestation, index) in prestations" :key="index"
                class="max-h-max flex text-left p-2 lg:m-2 ring-1 ring-grey hover:ring-2 hover:ring-indigo-600 duration-500"
                @click="selectOnePrestationFromDiv(index)"
            >
            <div class="ml-10 basis-3/4">
                {{ prestation.detail }}<br/>
                <div class="m-2 italic">
                    {{ getDetail(prestation) }}
                </div>
            </div>
            <div v-if="!isDisabled(prestation)">
            <!-- :disabled="isDisabled(prestation)"  -->
                <input ref="prestation" type="checkbox" :value="index" v-model="prestation.selected" @click="selectOnePrestation" />
            </div>
        </div>
    </div>
</template>
<style scoped>
@media all and (max-height:500px) {
.overflow {
    overflow: auto;
    max-height: 30vh;
}
}
</style>
