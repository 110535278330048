const defaultSteps = [
    {
        "step_no": 1,
        "step_valid": false,
        "name": "Prestations",
        "header": "Choisissez votre prestation :"
    },
    {
        "step_no": 2,
        "step_valid": false,
        "name": "Formules",
        "header": "Choisissez votre formules :"
    },
    {
        "step_no": 3,
        "step_valid": false,
        "name": "Rendez-vous",
        "header": "Sélectionner une date pour votre prestation :"
    },
    {
        "step_no": 4,
        "step_valid": false,
        "name": "Coordonnées",
        "header": "Entrez vos coordonnées :"
    },
];

export default defaultSteps;
